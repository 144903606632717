<template>
  <div class="container">
    <CCard>
        <CCardHeader color="primary" style="color:white;">Detail Informasi Pengajuan Draft Produk Hukum</CCardHeader>
        <CCardBody>
            <div class="row">
                <div class="col-lg-6">
                    <CCard>
                        <table class="table" style="white-space: normal !important;">
                            <tr>
                                <td style="width: 150px">Judul</td>
                                <td style="width: 10px">:</td>
                                <td><div v-html="document.tittle"></div></td>
                            </tr>
                            <tr>
                                <td>Jenis Dokumen</td>
                                <td>:</td>
                                <td>{{ document.document_type }}</td>
                            </tr>
                            <tr>
                                <td>Nomor Dokumen</td>
                                <td>:</td>
                                <td>{{ document.document_number }}</td>
                            </tr>
                            <tr>
                                <td>Perangkat Daerah</td>
                                <td>:</td>
                                <td>{{ document.user ? document.user.regional_apparatus_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Penyusun</td>
                                <td>:</td>
                                <td>{{ document.proposer }}</td>
                            </tr>
                            <tr>
                                <td>No. Whatsapp / Telephone</td>
                                <td>:</td>
                                <td>{{ document.signer }}</td>
                            </tr>
                            <tr>
                                <td>Bagian / Bidang / Sub</td>
                                <td>:</td>
                                <td>{{ document.unit }}</td>
                            </tr>
                            <tr>
                                <td>Legal Drafter</td>
                                <td>:</td>
                                <td><b>{{ document.legal_drafter ? document.legal_drafter.full_name : '-' }}</b></td>
                            </tr>
                            <tr>
                                <td>Tanggal Pengajuan</td>
                                <td>:</td>
                                <td>{{ this.$moment(document.date).format("Do MMMM YYYY") }}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Penetapan</td>
                                <td>:</td>
                                <td>
                                    {{ 
                                            document.determination_date 
                                            ? this.$moment(document.determination_date).format("Do MMMM YYYY") 
                                            : 'Belum Ditetapkan' 
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>Tanggal Dibuat</td>
                                <td>:</td>
                                <td>{{ this.$moment(document.created_at).format("Do MMMM YYYY HH:mm") }}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Diupdate</td>
                                <td>:</td>
                                <td>{{ this.$moment(document.updated_at).format("Do MMMM YYYY HH:mm") }}</td>
                            </tr>
                            <tr>
                                <td>No. Telpn / Wa Penyusun</td>
                                <td>:</td>
                                <td>{{ document.signer }}</td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>:</td>
                                <td>
                                    <span v-if="document.status == 'PROSES' || document.status == 'DIEDIT'" class="badge bg-warning" style="color:white">{{ document.status }}</span>
                                    <span v-else-if="document.status == 'PENGAJUAN'" class="badge bg-dark" style="color:white">{{ document.status }}</span>
                                    <span v-else-if="document.status == 'DIKEMBALIKAN'" class="badge bg-danger" style="color:white">{{ document.status }}</span>
                                    <span v-else-if="document.status == 'LEGAL DRAFTING'" class="badge bg-info" style="color:white">{{ document.status }}</span>
                                    <span v-else-if="document.status == 'REVISI'" class="badge bg-danger" style="color:white">{{ document.status }}</span>
                                    <span v-else class="badge bg-primary" style="color:white">{{ document.status }}</span>
                                </td>
                            </tr>
                        </table>
                    </CCard>
                    <CCard>
                        <CCardHeader color="primary" style="color:white;">Informasi Verifikator</CCardHeader>
                        <table class="table" style="white-space: normal !important;">
                            <tr>
                                <td>Admin </td>
                                <td>:</td>
                                <td>{{ document.admin_verified ? document.admin_verified.full_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Admin Approved</td>
                                <td>:</td>
                                <td>{{ document.admin_verified_at ? this.$moment(document.admin_verified_at).format("Do MMMM YYYY") : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Legal Drafter </td>
                                <td>:</td>
                                <td>{{ document.legal_drafter ? document.legal_drafter.full_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Legal Drafter Approved</td>
                                <td>:</td>
                                <td>{{ document.legal_drafter_verified_at ? this.$moment(document.legal_drafter_verified_at).format("Do MMMM YYYY") : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Suncang </td>
                                <td>:</td>
                                <td>{{ document.suncang_verified ? document.suncang_verified.full_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Suncang Approved</td>
                                <td>:</td>
                                <td>{{ document.suncang_verified_at ? this.$moment(document.suncang_verified_at).format("Do MMMM YYYY") : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Analis Hukum </td>
                                <td>:</td>
                                <td>{{ document.kasubag_verified ? document.kasubag_verified.full_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Analis Hukum Approved</td>
                                <td>:</td>
                                <td>{{ document.kasubag_verified_at ? this.$moment(document.kasubag_verified_at).format("Do MMMM YYYY") : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Kabag </td>
                                <td>:</td>
                                <td>{{ document.kabag_verified ? document.kabag_verified.full_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Kabag Approved</td>
                                <td>:</td>
                                <td>{{ document.kabag_verified_at ? this.$moment(document.kabag_verified_at).format("Do MMMM YYYY") : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Asisten </td>
                                <td>:</td>
                                <td>{{ document.asistant_verified ? document.asistant_verified.full_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Asisten Approved</td>
                                <td>:</td>
                                <td>{{ document.asistant_verified_at ? this.$moment(document.asistant_verified_at).format("Do MMMM YYYY") : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Sekda </td>
                                <td>:</td>
                                <td>{{ document.sekda_verified ? document.sekda_verified.full_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Sekda Approved</td>
                                <td>:</td>
                                <td>{{ document.sekda_verified_at ? this.$moment(document.sekda_verified_at).format("Do MMMM YYYY") : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Bupati </td>
                                <td>:</td>
                                <td>{{ document.bupati_verified ? document.bupati_verified.full_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Bupati Approved</td>
                                <td>:</td>
                                <td>{{ document.bupati_verified_at ? this.$moment(document.bupati_verified_at).format("Do MMMM YYYY") : '-' }}</td>
                            </tr>
                        </table>
                    </CCard>
                </div>
                <div class="col-lg-6">
                    <div v-if="document.draft_sk" >
                        <iframe :src="document.draft_sk" width="100%" height="600" type="application/pdf"></iframe>
                    </div>
                    
                    <br /><br />
                    <CCard>
                        <CCardHeader color="primary" style="color:white;">File Lampiran Dokumen</CCardHeader>
                        <div class="table-responsive">
                            <table class="table">
                                <tr>
                                    <th style="width: 10px">No.</th>
                                    <th>Versi</th>
                                    <th>File</th>
                                    
                                </tr>
                                <tr v-for="(_status, index) in document.document_attachment" :key="_status.id">
                                    <template v-if="_status.is_file">
                                        <td style="width: 10px">{{ index +1 }}.</td>
                                        <td>{{ _status.tittle }}</td>
                                        <td>
                                            <a :href="_status.description" class="m-1 btn btn-success btn-sm" >
                                                Download File
                                            </a>
                                        </td>
                                        
                                    </template>
                                </tr>
                            </table>
                        </div>
                    </CCard>
                    <CCard>
                        <CCardHeader color="primary" style="color:white;">Surat Pengantar & Dokumen Pendukung</CCardHeader>
                        <div class="table-responsive">
                            <table class="table">
                                <tr>
                                    <th style="width: 10px">No.</th>
                                    <th style="width: 100px">File</th>
                                    <th>Tanggal</th>
                                </tr>
                                <tr v-for="(_status, index) in document.document_support" :key="_status.id">
                                    <td style="width: 10px">{{ index +1 }}.</td>
                                    <td>
                                        <a :href="_status.file" class="m-1 btn btn-success btn-sm" >
                                            Download File
                                        </a>
                                    </td>
                                    <td>{{ format_date(_status.created_at) }}</td>
                                </tr>
                            </table>
                        </div>
                    </CCard>
                    <CCard  v-if="user.verificator !== 0">
                        <CCardHeader color="primary" style="color:white;">Status Riwayat Dokumen</CCardHeader>
                        <div class="table-responsive">
                            <table class="table">
                                <tr>
                                    <th style="width: 10px">No.</th>
                                    <th>Status</th>
                                    <th>Catatan</th>
                                    <th>Pencatat</th>
                                    <th>Tanggal</th>
                                </tr>
                                <tr v-for="(_status, index) in document.document_status" :key="_status.id">
                                    <td style="width: 10px">{{ index +1 }}.</td>
                                    <td>
                                         <span v-if="_status.status == 'PROSES' || _status.status == 'DIEDIT'" class="badge bg-warning" style="color:white">{{ _status.status }}</span>
                                        <span v-else-if="_status.status == 'PENGAJUAN'" class="badge bg-dark" style="color:white">{{ _status.status }}</span>
                                        <span v-else-if="_status.status == 'DIKEMBALIKAN'" class="badge bg-danger" style="color:white">{{ _status.status }}</span>
                                        <span v-else-if="_status.status == 'LEGAL DRAFTING'" class="badge bg-info" style="color:white">{{ _status.status }}</span>
                                        <span v-else-if="_status.status == 'REVISI'" class="badge bg-danger" style="color:white">{{ _status.status }}</span>
                                        <span v-else class="badge bg-primary" style="color:white">{{ _status.status }}</span>
                                    </td>
                                    <td>
                                    <template v-if="_status.status === 'REVISI' || _status.status === 'KOMENTAR'">
                                        <CButton class="m-2 btn btn-success btn-sm" @click="revisi(_status)">Lihat Revisi / Komentar </CButton>

                                    </template>
                                    <template v-else>
                                        {{ _status.remark }}
                                    </template>
                                    </td>

                                    
                                    <td>{{ _status.users.full_name }}</td>
                                    <td>{{ format_date(_status.created_at) }}</td>
                                </tr>
                            </table>
                        </div>
                    </CCard>
                    <CCard>
                        <CCardHeader color="primary" style="color:white;">QRCODE</CCardHeader>
                        <qr-code class="p-4 text-center" :text="document.draft_sk"></qr-code>
                    </CCard>
                </div>
            </div>
        </CCardBody>
        
        <CCardFooter v-if="user.verificator === 0" >
            <CButton class="m-1 btn btn-success" @click="edit() " v-if="!document.legal_drafter_verified_at"
                >
                Edit Dokumen
            </CButton>
            <CButton class="m-1 btn btn-primary" v-if="!document.admin_verified && (document.status == 'DIEDIT' || document.status == 'DIKEMBALIKAN')" @click="rePropose()" 
                >
                Ajukan Kembali
            </CButton>
        </CCardFooter>

        <CCardFooter v-if="user.verificator === 1" >
            <button
                class="btn btn-sm btn-primary ml-2"
                @click="addModal()"
              >
                
                Merge Dokument Draft
              </button>
              <button
                class="btn btn-sm btn-primary ml-2"
                @click="penomoran()"
              >
                Penomoran
              </button>
              <button
                class="btn btn-sm btn-primary ml-2"
                @click="uploadEoffice()"
              >
                Upload Ke E-Office
              </button>
        </CCardFooter>
        
        <CCardFooter v-if="user.verificator === 1 && !document.admin_verified && document.status == 'PENGAJUAN'">
            <CButton 
                class="m-1 btn btn-primary"
                @click="approve(38)" 
                >
                Acc Intan
            </CButton>
            <CButton 
                class="m-1 btn btn-primary"
                @click="approve(39)" 
                >
                Acc Quyna
            </CButton>
            <CButton 
                class="m-1 btn btn-primary"
                @click="approve(40)" 
                >
                Acc Ramadhan
            </CButton>
            <CButton 
                class="m-1 btn btn-primary"
                @click="approve(41)" 
                >
                Acc Hilwa
            </CButton>
            <CButton 
                class="m-1 btn btn-primary"
                @click="approve(42)" 
                >
                Acc Fikri
            </CButton>
            <CButton 
                class="m-1 btn btn-primary"
                @click="approve(43)" 
                >
                Acc Ahlul
            </CButton>
            <CButton 
                class="m-1 btn btn-primary"
                @click="approve(44)" 
                >
                Acc Rizky
            </CButton>
            <CButton 
                class="m-1 btn btn-primary"
                @click="approve(45)" 
                >
                Acc Eka 
            </CButton>
            <CButton 
                class="m-1 btn btn-primary"
                @click="approve(46)" 
                >
                Acc Adi
            </CButton>
            <CButton 
                class="m-1 btn btn-primary"
                @click="approve(56)" 
                >
                Acc Zaenal
            </CButton>
            <CButton 
                class="m-1 btn btn-primary"
                @click="approve()" 
                >
                Acc Random
            </CButton>
            
            <CButton 
                class="m-1 btn btn-warning" 
                @click="return_doc()"
                >
                Dikembalikan
            </CButton>
        </CCardFooter>

        <CCardFooter v-else-if="user.verificator === 2 && document.admin_verified &&  (document.status == 'PROSES' || document.status == 'DIEDIT' || document.status == 'REVISI')">
            <CButton 
                class="m-1 btn btn-primary" 
                @click="approve()"
                    >
                Approve Legal Drafter
            </CButton>
            <CButton class="m-1 btn btn-success" @click="edit()"
                >
                Edit Dokumen
            </CButton>
            <CButton 
            class="m-1 btn btn-danger" 
            @click="return_doc()"
                >
                Revisi
            </CButton>
            <button
                class="btn btn-primary ml-2"
                @click="addModal()"
              >
                
                Merge Dokument Draft
            </button>
        </CCardFooter>

        <CCardFooter v-else-if="user.verificator === 3 && document.status == 'LEGAL DRAFTING'">
            <CButton 
                class="m-1 btn btn-primary" 
                @click="approve()"
                    >
                    Approve Suncang
            </CButton>
             <CButton 
                class="m-1 btn btn-danger" 
                @click="return_doc()"
                    >
                    Revisi
            </CButton>
        </CCardFooter>

        <CCardFooter v-else-if="user.verificator === 4  && document.status == 'APPROVED SUNCANG'">
            <CButton 
                class="m-1 btn btn-primary" 
                @click="approve()"
                    >
                Approve Analis Hukum
            </CButton>
            <CButton 
            class="m-1 btn btn-danger" 
            @click="return_doc()"
                >
                Revisi
            </CButton>
        </CCardFooter>

        <CCardFooter v-else-if="user.verificator === 5  && document.status == 'APPROVED ANALIS HUKUM'">
            <CButton 
                class="m-1 btn btn-primary" 
                @click="approve()"
                    >
                Approve Kabag
            </CButton>
            <CButton 
            class="m-1 btn btn-danger" 
            @click="return_doc()"
                >
                Revisi
            </CButton>
        </CCardFooter>

        <CCardFooter v-else-if="user.verificator === 6  && (document.status == 'APPROVED KABAG' || document.status == 'PENOMORAN')">
            <CButton 
                class="m-1 btn btn-primary" 
                @click="approve()"
                    >
                Approve Asisten
            </CButton>
            <CButton 
                class="m-1 btn btn-danger" 
                @click="return_doc()"
                    >
                    Revisi
            </CButton>
        </CCardFooter>

        <CCardFooter v-else-if="user.verificator === 7  && document.status == 'APPROVED ASSISTANT'">
            <CButton 
                class="m-1 btn btn-primary"
                @click="approve()" 
                    >
                Approve Sekda
            </CButton>
            <CButton 
                class="m-1 btn btn-danger" 
                @click="return_doc()"
                    >
                    Revisi
            </CButton>
        </CCardFooter>

        <CCardFooter v-else-if="user.verificator === 8  && document.status == 'APPROVED SEKDA'">
            <CButton 
                class="m-1 btn btn-primary"
                @click="approve()" 
                    >
                Approve Bupati
            </CButton>
            <CButton 
                class="m-1 btn btn-danger" 
                @click="return_doc()"
                    >
                    Revisi
            </CButton>
        </CCardFooter>

        <!-- untuk komentar -->
        <CCardFooter v-if="user.verificator === 8 || 
            user.verificator === 7 || 
            user.verificator === 6 || 
            user.verificator === 5 ||
            user.verificator === 4 || user.verificator === 3">

            <CButton 
                class="m-1 btn-sm btn btn-success" @click="komentar()"
                    >
                    Komentar
            </CButton>
        </CCardFooter>

    </CCard>
    <CModal
      size="lg"
      title="Upload File"
        
      centered
      :color="isUpdate ? 'success' : 'primary'"
      :show.sync="createModal"
    >
      <div class="row">
        <div class="col-12">
            <div
                class="card border d-flex justify-content-center text-center"
                style="height: 150px"
            >
                <div class="align-self-center mb-3">
                    <img src="@/assets/upload.png" width="100px" />
                </div>
                <button
                    class="btn btn-sm btn-primary align-self-center"
                    style="width: 15%"
                    @click="upload"
                    >
                    Upload Disini
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="div">
                    <input
                        hidden
                        type="file"
                        class="form-control"
                        ref="uploadField"
                        @change="selectFile"
                    />
                </div>
            </div>
        </div>
      </div>
      <div class="row" v-if="draft_sk !== ''">
            <div class="col-md-2" >
                <CCardLink :href="draft_sk" target="_blank" class="">
                    <img src="@/assets/attachment.png" width="100%" />
                </CCardLink>
                <br />
                <a :href="draft_sk" target="_blank" style="width: 100%;"
                    class="btn btn-sm btn-secondary align-self-center"
                    >
                    Download
                </a>
                <br />
            </div>
        </div>
      <template slot="footer">
        <div>
          <button @click="merge" v-if="!isUpdate" class="btn btn-primary">
            Merge
          </button>
        </div>
      </template>
    </CModal>
  </div>

  
</template>

<script>
import { uploadFile } from "@/utils/fileUpload";
export default {
    data() {
        return {
            document: [],
            user: {},
            createModal: false,
            draft_sk:'',
        }
    },
    methods: {
        penomoran() {
            const form = {
                id: this.document.id,
                total_attachment: this.document.total_attachment,
                document_type: this.document.document_type,
                document_type_id: this.document.document_type_id,
                document_activity_id: this.document.document_activity_id,
                title: this.document.tittle,
                document_number: this.document.document_number,
                signer: this.document.signer,
                proposer: this.document.proposer,
                unit: this.document.unit,
                date: this.document.date,
                determination_date: this.document.determination_date,
                penomoran: true,
            }

            const verificator = {
                admin_verified: this.document.admin_verified,
                admin_verified_at: this.document.admin_verified_at,
                legal_drafter_verified: this.document.legal_drafter_verified,
                legal_drafter_verified_at: this.document.legal_drafter_verified_at,
                suncang_verified: this.document.suncang_verified,
                suncang_verified_at: this.document.suncang_verified_at,
                kasubag_verified: this.document.kasubag_verified,
                kasubag_verified_at: this.document.kasubag_verified_at,
                kabag_verified: this.document.kabag_verified,
                kabag_verified_at: this.document.kabag_verified_at,
                asistant_verified: this.document.asistant_verified,
                asistant_verified_at: this.document.asistant_verified_at,
                sekda_verified: this.document.sekda_verified,
                sekda_verified_at: this.document.sekda_verified_at,
                bupati_verified: this.document.bupati_verified,
                bupati_verified_at: this.document.bupati_verified_at
            }

            // convert string to json
            if (this.document.document_decision.length > 0) {
                this.document.document_decision.forEach(element => {
                    if (element.extend_form) {
                        element.extend_form = JSON.parse(element.extend_form);
                    } else {
                        element.extend_form = [];
                    }
                });
            }
            
            this.$store.commit('document/setDocument', form);
            this.$store.commit('document/setDocumentRemembers', this.document.document_remember);
            this.$store.commit('document/setDocumentNotices', this.document.document_notice);
            this.$store.commit('document/setDocumentDecisions', this.document.document_decision);
            this.$store.commit('document/setDocumentAttachments', this.document.document_attachment);
            this.$store.commit('document/setDocumentSupports', this.document.document_support);
            this.$store.commit('document/setDocumentConsiders', this.document.document_consider);
            this.$store.commit('document/setDocumentCopies', this.document.document_copy);
            this.$store.commit('document/setVerificator', verificator);
            this.$router.push({ path: 'DocumentFrom' });
        },
        uploadEoffice() {
            this.$swal.fire({
                icon: 'warning',
                html: 'Apakah yakin akan mengupload dokument ke e-office ?',
                showCancelButton: true,
                confirmButtonText: 'Ya',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    var loading = this.$loading.show();
                    this.$store
                        .dispatch('document/uploadEoffice', this.document.id)
                        .then(() => {
                            this.$swal.fire({
                                icon: 'success',
                                text: 'Berhasil Upload Document Ke E-Office!',
                            });
                            this.getData();
                        })
                        .catch((e) => {
                            console.log(e)
                        })
                        .finally(() => {
                            loading.hide();
                        });
                } 
            })
        },
        edit() {
            const form = {
                id: this.document.id,
                total_attachment: this.document.total_attachment,
                document_type: this.document.document_type,
                document_type_id: this.document.document_type_id,
                document_activity_id: this.document.document_activity_id,
                title: this.document.tittle,
                document_number: this.document.document_number,
                signer: this.document.signer,
                proposer: this.document.proposer,
                unit: this.document.unit,
                date: this.document.date,
                determination_date: this.document.determination_date,
                penomoran: false,
                draft_sk: this.document.draft_sk
            }

            const verificator = {
                admin_verified: this.document.admin_verified,
                admin_verified_at: this.document.admin_verified_at,
                legal_drafter_verified: this.document.legal_drafter_verified,
                legal_drafter_verified_at: this.document.legal_drafter_verified_at,
                suncang_verified: this.document.suncang_verified,
                suncang_verified_at: this.document.suncang_verified_at,
                kasubag_verified: this.document.kasubag_verified,
                kasubag_verified_at: this.document.kasubag_verified_at,
                kabag_verified: this.document.kabag_verified,
                kabag_verified_at: this.document.kabag_verified_at,
                asistant_verified: this.document.asistant_verified,
                asistant_verified_at: this.document.asistant_verified_at,
                sekda_verified: this.document.sekda_verified,
                sekda_verified_at: this.document.sekda_verified_at,
                bupati_verified: this.document.bupati_verified,
                bupati_verified_at: this.document.bupati_verified_at
            }

            // convert string to json
            if (this.document.document_decision.length > 0) {
                this.document.document_decision.forEach(element => {
                    if (element.extend_form) {
                        element.extend_form = JSON.parse(element.extend_form);
                    } else {
                        element.extend_form = [];
                    }
                });
            }
            
            this.$store.commit('document/setDocument', form);
            this.$store.commit('document/setDocumentRemembers', this.document.document_remember);
            this.$store.commit('document/setDocumentNotices', this.document.document_notice);
            this.$store.commit('document/setDocumentDecisions', this.document.document_decision);
            this.$store.commit('document/setDocumentAttachments', this.document.document_attachment);
            this.$store.commit('document/setDocumentSupports', this.document.document_support);
            this.$store.commit('document/setDocumentConsiders', this.document.document_consider);
            this.$store.commit('document/setDocumentCopies', this.document.document_copy);
            this.$store.commit('document/setVerificator', verificator);
            this.$router.push({ path: 'DocumentFrom' });
        },
        format_date(date) {
            return this.$moment(new Date(date)).format("Do MMMM YYYY HH:mm");
        },
        approve(id = null) {
            this.$swal.fire({
                icon: 'warning',
                html: 'Apakah yakin akan Mengapprove data?',
                showCancelButton: true,
                confirmButtonText: 'Ya, Approve',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.submit(id);
                } 
            })
        },
        revisi(revisi) {

            if (this.user.id == revisi.user_id) {
                let hasil = '';
                this.$swal.fire({
                    title: 'Revisi',
                    html: `<textarea class="swal2-textarea" style="font-size: 12px; height: 200px; width: 400px;">${revisi.remark}</textarea>`,
                    showCancelButton: true,
                    confirmButtonText: 'Edit',
                    confirmButtonColor: '#3085d6',
                    preConfirm: () => {
                        // Mengambil nilai dari textarea setelah diklik tombol Edit
                        hasil = document.querySelector('.swal2-textarea').value;
                    }
                }).then((result) => {
                    // Menangani logika setelah pengguna mengklik tombol Close
                    if (result.value) {
                        let data = {
                            id: revisi.id,
                            revisi: hasil
                        }

                        let loading = this.$loading.show();
                        this.$store
                            .dispatch('document/editRevisi', data)
                            .then(() => {
                                this.$swal.fire({
                                    icon: 'success',
                                    text: 'Berhasil Edit Revisi!',
                                });
                                loading.hide();
                                this.getData();
                            })
                            .catch((e) => {
                                loading.hide();
                                console.log(e)
                            })
                            .finally(() => {
                                loading.hide();
                            });
                    }
                });
            } else {
                this.$swal.fire({
                    title: 'Revisi',
                    html: `<textarea class="swal2-textarea" style="font-size: 12px; height: 200px; width: 400px;" readonly>${revisi.remark}</textarea>`,
                    showCancelButton: false,
                    confirmButtonText: 'Close',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33'
                });
            }
        },
        submit(id = null) {
            let data = {
                document_id : this.$route.query.id,
                remark : null,
                status :null,
                sign : null,
                legal : id
            }

            let path = null;

            if(this.user.verificator === 1){
                console.log("User is a verificator");
                data.remark = "DI APPROVE ADMIN";
                data.status = "PROSES";
                path = "document/approveAdmin";
            }
            else if(this.user.verificator === 2){
                data.remark = "DI APPROVE LEGAL DRAFTER";
                data.status = "LEGAL DRAFTING";
                path = "document/approveLegalDrafter";
            }
            else if(this.user.verificator === 3){
                data.remark = "DI APPROVE SUNCANG";
                data.status = "APPROVED SUNCANG";
                path = "document/approveSuncang";
            }
            else if(this.user.verificator === 4){
                data.remark="DI APPROVE ANALIS HUKUM";
                data.status="APPROVED ANALIS HUKUM";
                path ="document/approveKasubag";
                data.sign = "TTD Kasubag"
            }
            else if(this.user.verificator === 5){
                data.remark="DI APPROVE KABAG";
                data.status="APPROVED KABAG";
                path ="document/approveKabag";
                data.sign = "TTD Kabag"
            }
            else if(this.user.verificator === 6){
                data.remark="DI APPROVE ASSISTANT";
                data.status="APPROVED ASSISTANT";
                path ="document/approveAssistant";
                data.sign = "TTD ASSISTANT"
                
            }
            else if(this.user.verificator === 7){
                data.remark="DI APPROVE SEKDA";
                data.status="APPROVED SEKDA";
                path ="document/approveSekda";
                data.sign = "TTD Sekda"
            } 
            else if(this.user.verificator === 8){
                data.remark="DI APPROVE BUPATI";
                data.status="APPROVED BUPATI";
                path ="document/approveBupati";
                data.sign = "TTD Bupati"
            } 

            var loading = this.$loading.show();
            this.$store
                .dispatch(path, data)
                .then(() => {
                    this.$swal.fire({
                        icon: 'success',
                        text: 'Berhasil Mengapprove Document!',
                    });
                    this.getData();
                })
                .catch((e) => {
                    console.log(e)
                })
                .finally(() => {
                    loading.hide();
                });
        },
        merge() {
            var loading = this.$loading.show();
            this.$store
                .dispatch('document/updateNaskah', {
                    id: this.$route.query.id,
                    draft_sk: this.draft_sk
                })
                .then(() => {
                    this.$swal.fire({
                        icon: 'success',
                        text: 'Berhasil Merge Document!',
                    });
                    this.draft_sk = '';
                    this.createModal = false;
                    this.getData();
                })
                .catch((e) => {
                    console.log(e)
                })
                .finally(() => {
                    loading.hide();
                });
        },
        rePropose() {

            this.$swal.fire({
                icon: 'warning',
                html: 'Apakah yakin akan mengajukan kembali?',
                showCancelButton: true,
                confirmButtonText: 'Ya, Ajukan',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    let data = {
                        document_id : this.$route.query.id,
                        remark : "DI AJUKAN KEMBALI",
                        status : "PENGAJUAN",
                    }

                    let path = "document/rePropose";
                    let loading = this.$loading.show();
                    this.$store
                        .dispatch(path, data)
                        .then(() => {
                            this.$swal.fire({
                                icon: 'success',
                                text: 'Berhasil Diajukan Kembali!',
                            });
                            this.getData();
                        })
                        .catch((e) => {
                            console.log(e)
                        })
                        .finally(() => {
                            loading.hide();
                        });
                } 
            })
        },
        return_doc() {
            this.$swal.fire({
                icon: 'info',
                html: 'Silahkan memberikan alasan / keterangan terlebih dahulu!',
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Kirim',
                showLoaderOnConfirm: true,
                preConfirm: (alasan) => {

                    let data = {
                        document_id : this.$route.query.id,
                        remark : null,
                        status :null,
                        sign : null
                    }

                    if(this.user.verificator === 1){
                        data.remark = alasan;
                        data.status = "DIKEMBALIKAN";
                        let path = "document/approveAdmin";
                        let loading = this.$loading.show();
                        this.$store
                            .dispatch(path, data)
                            .then(() => {
                                this.$swal.fire({
                                    icon: 'success',
                                    text: 'Berhasil Mengembalikan Document!',
                                });
                                this.getData();
                            })
                            .catch((e) => {
                                loading.hide();
                                console.log(e)
                            })
                            .finally(() => {
                                loading.hide();
                            });
                    }
                    else {
                        data.remark = alasan;
                        data.status = "REVISI";
                        let path;
                        if(this.user.verificator === 2){
                             path = "document/approveLegalDrafter";
                        }
                        else if(this.user.verificator === 3){
                            path = "document/approveSuncang";
                        }
                        else if(this.user.verificator === 4){
                            path = "document/approveKasubag";
                        }
                        else if(this.user.verificator === 5){
                            path = "document/approveKabag";
                        }
                        else if(this.user.verificator === 6){
                            path = "document/approveAssistant";
                        }
                        else if(this.user.verificator === 7){
                            path = "document/approveSekda";
                        }
                        else if(this.user.verificator === 8){
                            path = "document/approveBupati";
                        }
                        
                        let loading = this.$loading.show();
                        this.$store
                            .dispatch(path, data)
                            .then(() => {
                                this.$swal.fire({
                                    icon: 'success',
                                    text: 'Berhasil Menambahkan Status Revisi!',
                                });
                                this.getData();
                            })
                            .catch((e) => {
                                loading.hide();
                                console.log(e)
                            })
                            .finally(() => {
                                loading.hide();
                            });
                    } 
                },
            })
        },
        komentar() {
            this.$swal.fire({
                icon: 'info',
                html: 'Silahkan memberikan Komentar / keterangan terlebih dahulu!',
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Kirim',
                showLoaderOnConfirm: true,
                preConfirm: (alasan) => {

                    let data = {
                        document_id : this.$route.query.id,
                        remark : null,
                        status :null,
                        sign : null
                    }

                    data.remark = alasan;
                    data.status = "KOMENTAR";
                    let path = "document/komentar";
                    let loading = this.$loading.show();
                    this.$store
                        .dispatch(path, data)
                        .then(() => {
                            this.$swal.fire({
                                icon: 'success',
                                text: 'Berhasil Memberi Komentar Document!',
                            });
                            this.getData();
                        })
                        .catch((e) => {
                            loading.hide();
                            console.log(e)
                        })
                        .finally(() => {
                            loading.hide();
                        });
                },
            })
        },
        getData() {
            let loading = this.$loading.show();
            this.$store.dispatch("document/show", this.$route.query.id).then(
                (data) => {
                    const result = data;
                    if (result.status == 200) {
                        this.document = result.data;
                        
                    }
                    loading.hide();
                },
                (error) => {
                    loading.hide();
                }
            );
        },
        addModal() {       
            this.createModal = true;
        },
        upload() {
                this.$refs.uploadField.click();
        },
        selectFile(event) {
                this.file = event.target.files[0];
                var loading = this.$loading.show();
                uploadFile(this.file)
                    .then((resp) => {
                        
                        this.draft_sk = resp;
                        loading.hide();
                        this.$swal.fire({
                            icon: 'success',
                            text: 'File berhasil diupload !!',
                        });
                    })
                    .catch((e) => {
                        loading.hide();
                        this.$swal.fire({
                            icon: 'warning',
                            text: 'Terjadi kesalahan, ' + e,
                        })
                    });
            },
    },
    mounted() {
        this.getData();
        const user = JSON.parse(localStorage.getItem('user'));
        this.user = user;
    }
}
</script>

<style>

</style>